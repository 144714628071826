import {
  $materialShopCart,
  $materialShopLoginToken,
  $materialShopOrderId,
  IMaterialShopCartItem,
  materialShopAddItem,
  $isOrderIdIsVerified,
} from '@services/stores/materialShop'
import { IMaterialShopValues } from '@/types/materialshopvalues'
import { IntlShape } from 'react-intl'

const fetchAndPopulateCartFromOrderId = async (
  allContentfulMaterialShopItems: IMaterialShopCartItem[],
  intl: IntlShape,
  retrievedItems: IMaterialShopValues | null
) => {
  const socialMediaItems = allContentfulMaterialShopItems.filter(
    (item) => item.category?.identifier === 'pressSocial'
  )
  const orderId = $materialShopOrderId.get()
  const token = $materialShopLoginToken.get()

  const foundShoppingItem = (id: string) =>
    allContentfulMaterialShopItems.find((node) => node.id === id)

  const isOrderIdIsVerified = $isOrderIdIsVerified.get()
  if (
    orderId !== undefined &&
    isOrderIdIsVerified === false &&
    !!token &&
    !!retrievedItems
  ) {
    try {
      const retrievedOrders: IMaterialShopValues = {
        ...retrievedItems,
        socialsPressPackage: socialMediaItems.filter(
          (item) => item.id === '991ec469-ddfd-5067-8563-c4a706bef6e6'
        ),
        socialsSocialMedia: socialMediaItems.filter(
          (item) => item.id === '3565700d-9093-5d0e-b8c5-b30935f57488'
        ),
      }
      $materialShopCart.set({
        ...$materialShopCart.get(),
        contactData: retrievedItems.contactData,
        multimediaPillar: retrievedItems.multimediaPillar,
        feedback: retrievedItems.feedback,
      })
      let items: IMaterialShopCartItem[] = []

      // BIG EVENTS
      // ----------------------------------------

      const bigEventGroups: any = retrievedOrders.bigEvents.reduce(
        (
          acc: {
            [key: string]: {
              [key: string]: string | null | { [key: string]: string }
            }[]
          },
          bigEvent
        ) => {
          if (!acc[bigEvent.id]) {
            acc[bigEvent.id] = []
          }
          acc[bigEvent.id].push(bigEvent)
          return acc
        },
        {}
      )
      items = items.concat(
        Object.keys(bigEventGroups)
          .map((id) => {
            const bigEventsSections: IMaterialShopValues['bigEvents'] =
              bigEventGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null
            return {
              ...foundShopItem,
              title: intl.formatMessage(
                {
                  id: `materialshop.category.${foundShopItem.category.identifier}.label`,
                },
                { value: foundShopItem.name }
              ),
              values: {
                sections: bigEventsSections.map((bigEvent: any) => ({
                  contactPerson: bigEvent.contact.contactPerson,
                  date: bigEvent.date ? bigEvent.date.slice(0, -9) : null,
                  email: bigEvent.contact.email,
                  numberOfExpectedVisitors: bigEvent.numberOfExpectedVisitors,
                  phone: bigEvent.contact.phone,
                  reason: bigEvent.reason,
                })),
              },
            }
          })
          .filter((item) => item !== null)
      )

      // SMALL EVENTS
      // ----------------------------------------

      const smallEventGroups: { [key: string]: any[] } =
        retrievedOrders.smallEvents.reduce(
          (acc: { [key: string]: any[] }, smallEvent) => {
            if (!acc[smallEvent.id]) {
              acc[smallEvent.id] = []
            }
            acc[smallEvent.id].push(smallEvent)
            return acc
          },
          {}
        )

      items = items.concat(
        Object.keys(smallEventGroups)
          .map((id) => {
            const smallEventsValues: IMaterialShopValues['smallEvents'] =
              smallEventGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            return {
              ...foundShopItem,
              title: intl.formatMessage(
                {
                  id: `materialshop.category.${foundShopItem.category.identifier}.label`,
                },
                { value: foundShopItem.name }
              ),
              values: {
                sections: smallEventsValues.map((smallEvent: any) => ({
                  contactPerson: smallEvent.contact.contactPerson,
                  email: smallEvent.contact.email,
                  phone: smallEvent.contact.phone,
                  date: smallEvent.date ? smallEvent.date.slice(0, -9) : null,
                  reason: smallEvent.reason,
                  deliveryAddress: smallEvent.deliveryAddress ?? null,
                })),
              },
            }
          })
          .filter((item) => item !== null)
      )

      // POSTER
      // ----------------------------------------

      const posterGroups: {
        [key: string]: any[]
      } = retrievedOrders.poster.reduce(
        (acc: { [key: string]: any[] }, poster) => {
          if (!acc[poster.id]) {
            acc[poster.id] = []
          }
          acc[poster.id].push(poster)
          return acc
        },
        {}
      )

      items = items.concat(
        Object.keys(posterGroups)
          .map((id) => {
            const posterValues: IMaterialShopValues['poster'] = posterGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            const posterFields = posterValues.reduce(
              (acc: any, poster: any) => {
                acc[poster.size] = poster.numberOfPieces
                return acc
              },
              {}
            )

            return {
              ...foundShopItem,
              title: intl.formatMessage(
                {
                  id: `materialshop.category.${foundShopItem.category.identifier}.label`,
                },
                { value: foundShopItem.name }
              ),
              values: {
                fields: posterFields,
              },
            }
          })
          .filter((item) => item !== null)
      )

      // BRIDGE BANNERS
      // ----------------------------------------

      const bridgeBannersGroups: {
        [key: string]: any[]
      } = retrievedOrders.bridgeBanners.reduce(
        (acc: { [key: string]: any[] }, bridgeBanner) => {
          if (!acc[bridgeBanner.id]) {
            acc[bridgeBanner.id] = []
          }
          acc[bridgeBanner.id].push(bridgeBanner)
          return acc
        },
        {}
      )
      items = items.concat(
        Object.keys(bridgeBannersGroups)
          .map((id) => {
            const bridgeBannersValues: IMaterialShopValues['bridgeBanners'] =
              bridgeBannersGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            const bridgeBannersFields = bridgeBannersValues.reduce(
              (acc: any, bridgeBanner: any) => {
                acc[
                  bridgeBanner.windPermeable
                    ? 'windPermeable'
                    : 'notWindPermeable'
                ] = bridgeBanner.numberOfPieces
                return acc
              },
              {}
            )

            return {
              ...foundShopItem,
              title: intl.formatMessage(
                {
                  id: `materialshop.category.${foundShopItem.category.identifier}.label`,
                },
                { value: foundShopItem.name }
              ),
              values: {
                fields: bridgeBannersFields,
              },
            }
          })
          .filter((item) => item !== null)
      )

      // PROMOTION BANNERS
      // ----------------------------------------

      const promotionBannersGroups: {
        [key: string]: any[]
      } = retrievedOrders.promotionBanners.reduce(
        (acc: { [key: string]: any[] }, promotionBanner) => {
          if (!acc[promotionBanner.id]) {
            acc[promotionBanner.id] = []
          }
          acc[promotionBanner.id].push(promotionBanner)
          return acc
        },
        {}
      )
      items = items.concat(
        Object.keys(promotionBannersGroups)
          .map((id) => {
            const promotionBannersValues: IMaterialShopValues['promotionBanners'] =
              promotionBannersGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            const promotionBannersFields = promotionBannersValues.reduce(
              (acc: any, promotionBanner: any) => {
                acc[
                  promotionBanner.windPermeable
                    ? 'windPermeable'
                    : 'notWindPermeable'
                ] = promotionBanner.numberOfPieces
                return acc
              },
              {}
            )

            return {
              ...foundShopItem,
              title: intl.formatMessage(
                {
                  id: `materialshop.category.${foundShopItem.category.identifier}.label`,
                },
                { value: foundShopItem.name }
              ),
              values: {
                fields: promotionBannersFields,
              },
            }
          })
          .filter((item) => item !== null)
      )

      // PROMO STUFF
      // ----------------------------------------

      const promoStuffGroups: {
        [key: string]: any[]
      } = retrievedOrders.promoStuff.reduce(
        (acc: { [key: string]: any[] }, promoStuff) => {
          if (!acc[promoStuff.id]) {
            acc[promoStuff.id] = []
          }
          acc[promoStuff.id].push(promoStuff)
          return acc
        },
        {}
      )
      items = items.concat(
        Object.keys(promoStuffGroups)
          .map((id) => {
            const promoStuffValues: IMaterialShopValues['promoStuff'] =
              promoStuffGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            const promoStuffFields = promoStuffValues.reduce(
              (acc: any, poster: any) => {
                acc['numberOfPieces'] = poster.numberOfPieces
                return acc
              },
              {}
            )

            return {
              ...foundShopItem,
              title: intl.formatMessage(
                {
                  id: `materialshop.category.${foundShopItem.category.identifier}.label`,
                },
                { value: foundShopItem.name }
              ),
              values: {
                fields: promoStuffFields,
              },
            }
          })
          .filter((item) => item !== null)
      )

      // SOCIALS - PRESS PACKAGE
      // ----------------------------------------
      const socialsPressPackageGroups: any =
        retrievedOrders.socialsPressPackage?.reduce(
          (acc: { [key: string]: any[] }, socialsPP) => {
            if (!acc[socialsPP.id]) {
              acc[socialsPP.id] = []
            }
            acc[socialsPP.id].push(socialsPP)
            return acc
          },
          {}
        )

      items = items.concat(
        Object.keys(socialsPressPackageGroups)
          .map((id) => {
            const socialsPressPackageValues: IMaterialShopValues['socialsPressPackage'] =
              socialsPressPackageGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            const socialsOrderInCartChecked = socialsPressPackageValues?.reduce(
              (acc: any, _: any) => {
                acc['orderInCart'] = retrievedOrders.contactData.pressPackage
                return acc
              },
              {}
            )

            return {
              ...foundShopItem,
              orderInCart: socialsOrderInCartChecked.orderInCart,
            }
          })
          .filter((item) => item !== null)
      )

      // SOCIALS - SOCIAL MEDIA
      // ----------------------------------------
      const socialsSocialMediaGroups: any =
        retrievedOrders.socialsSocialMedia?.reduce(
          (acc: { [key: string]: any[] }, socialsSM) => {
            if (!acc[socialsSM.id]) {
              acc[socialsSM.id] = []
            }
            acc[socialsSM.id].push(socialsSM)
            return acc
          },
          {}
        )

      items = items.concat(
        Object.keys(socialsSocialMediaGroups)
          .map((id) => {
            const socialsSocialMediaValues: IMaterialShopValues['socialsSocialMedia'] =
              socialsSocialMediaGroups[id]
            const foundShopItem = foundShoppingItem(id)
            if (!foundShopItem) return null

            const socialsOrderInCartChecked = socialsSocialMediaValues?.reduce(
              (acc: any, _: any) => {
                acc['orderInCart'] =
                  retrievedOrders.contactData.socialMediaPackage
                return acc
              },
              {}
            )

            return {
              ...foundShopItem,
              orderInCart: socialsOrderInCartChecked.orderInCart,
            }
          })
          .filter((item) => item !== null)
      )

      items.forEach((item) => {
        materialShopAddItem(item, item.values)
      })

      return items
    } catch (error) {
      console.error('Error fetching order details:', error)
    }
  }
}

export default fetchAndPopulateCartFromOrderId
