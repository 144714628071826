import React, { ReactElement } from 'react'
import materialShopLogin from '@services/api/materialShopLogin'
import {
  $isOrderIdIsVerified,
  $materialShopLoginToken,
  $materialShopOrderId,
  $materialShopPaths,
  $materialShopSessionId,
} from '@services/stores/materialShop'
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import Container from '@objects/container'
import Headline from '@objects/headline'
import { TextField } from '@objects/formfields'
import Button from '@objects/button'
import { makeStyles } from '@material-ui/core'
import { useStore } from '@nanostores/react'
import materialShopGetOrdersFromUniqueId from '@services/api/materialShopGetOrdersFromUniqueId'
import fetchAndPopulateCartFromOrderId from '@utils/fetchAndPopulateCartFromOrderId'
import { useToggleComp } from '@hooks/index'

const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headline: {
    fontWeight: 700,
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))

export default function MaterialShopLoginForm(): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const materialShopPaths = useStore($materialShopPaths)
  const { toggleOverlay } = useToggleComp()

  const { allContentfulMaterialShopItems } = useStaticQuery(graphql`
    query {
      allContentfulMaterialShopItems: allContentfulMaterialShopItem {
        nodes {
          ...ContentfulMaterialShopItemFragment
        }
      }
    }
  `)

  return (
    <Formik
      initialValues={{
        token: '',
      }}
      onSubmit={(values, formikHelpers) => {
        materialShopLogin(values.token)
          .then((response: Response) => {
            if (response.status !== 202) {
              formikHelpers.setSubmitting(false)
              formikHelpers.setErrors({
                token: intl.formatMessage({
                  id: 'materialshop.form.error.password',
                }),
              })
              return
            }

            $materialShopSessionId.set(values.token)
            $materialShopLoginToken.set(values.token)

            const orderId = $materialShopOrderId.get()

            if (orderId) {
              materialShopGetOrdersFromUniqueId(orderId, values.token)
                .then((response: any) => {
                  if (response.status === 200) {
                    fetchAndPopulateCartFromOrderId(
                      allContentfulMaterialShopItems.nodes,
                      intl,
                      response.data
                    )
                    $isOrderIdIsVerified.set(true)
                    navigate(materialShopPaths.cart)
                  } else if (response.status === 404) {
                    console.error('error - status:', response.status)
                    toggleOverlay(true, {
                      type: 'orderId404Error',
                      trackingID: 'materialShop',
                      width: 'sm',
                      onAcceptance: () => toggleOverlay(false),
                    })
                    navigate(materialShopPaths.shop)
                    $isOrderIdIsVerified.set(false)
                  }
                })
                .catch((error) => {
                  console.error('order error', error)
                  toggleOverlay(true, {
                    type: 'orderIdError',
                    trackingID: 'materialShop',
                    width: 'sm',
                    onAcceptance: () => toggleOverlay(false),
                  })
                  navigate(materialShopPaths.shop)
                  $isOrderIdIsVerified.set(false)
                })
            } else {
              navigate(materialShopPaths.shop)
            }
          })
          .catch(() => {
            formikHelpers.setSubmitting(false)
            formikHelpers.setErrors({
              token: intl.formatMessage({
                id: 'materialshop.form.error.password',
              }),
            })
          })
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container className={classes.form} width={'sm'}>
          <Headline level={5} className={classes.headline}>
            <FormattedMessage id={'materialshop.login.headline'} />
          </Headline>
          <TextField
            variant="outlined"
            id="token"
            customVariant="form"
            name="token"
            type="password"
            label=""
            placeholder={intl.formatMessage({
              id: 'materialshop.form.password',
            })}
          />
          <Button
            className={classes.button}
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
            icon="ChevronRight"
            iconPosition="right"
            formSubmit
          >
            <FormattedMessage id={'materialshop.login.button'} />
          </Button>
        </Container>
      )}
    </Formik>
  )
}
