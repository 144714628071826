import axios from 'axios'

export default async function materialShopGetOrdersFromUniqueId(
  orderId: string,
  token: string
): Promise<any> {
  const response = await axios.get(
    `${process.env.GATSBY_MATERIALSHOP_SERVICE_URL}/api/orderformforministries/orders/${orderId}`,
    {
      headers: {
        accept: 'application/json',
        token: token,
      },
    }
  )
  return response
}
