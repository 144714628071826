import React, { ReactElement, useMemo } from 'react'
import Container from '@objects/container'
import { useIntl } from 'react-intl'
import BreadCrumbs from '@objects/breadcrumb'
import { $materialShopPaths } from '@services/stores/materialShop'
import MaterialShopLoginForm from '@components/forms/materialshoploginform'
import { useStore } from '@nanostores/react'

export default function RootIndex(): ReactElement {
  const intl = useIntl()
  const materialShopPaths = useStore($materialShopPaths)
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.shop.label',
        }),
        link: materialShopPaths.shop,
      },
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.login.label',
        }),
      },
    ]
  }, [])

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs crumbList={breadcrumbs} />
      </Container>
      <MaterialShopLoginForm />
    </section>
  )
}
