const axios = require('axios')

export default async function materialShopLogin(token: string): Promise<any> {
  const response = await axios.post(
    process.env.GATSBY_MATERIALSHOP_SERVICE_URL +
      '/api/orderformforministries/orders/login',
    { loginToken: token }
  )
  return response
}
